<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" v-show="!config.isDetailDisplay">
                <div class="h-b"><el-button type="primary" size="small" @click="addRole" v-right-code="'Sysrole:Search'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="delRole" v-right-code="'Sysrole:Removeroles'">删除</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="roleListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count) in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='RoleName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadRolePageList", () => this.reloadPageList());
            this.Event.$on("onAddRole", () => this.addRole());
            this.initialize();
        },
        data(){
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {
                      BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            }
        },
        methods: {
            reloadPageList() {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            onPageChange(param) {
                this.queryParam = param;
                this.$ajax.send("omsapi/sysrole/getlist", "post", param, data => {
                    this.dataSource = data;
                });
            },
            onDataSourceChange(ds)
            {
                var _this = this;

                    _this.dataSource = {
                        ColDefs: {
                            BodyFieldParams: []
                        },
                        Result: [],
                        TotalCount: 0
                };
                    _this.$nextTick(function () {
                        _this.dataSource = ds;

                    });


            },
            rowClick(row) {
                this.$ajax.send("omsapi/sysrole/getbyid", "get", { id: row.SysRoleId }, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            addRole() {
                this.$ajax.send("omsapi/sysrole/initial", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            delRole() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的角色.`, "error");
                    return false;
                }

                var isValid = true;
                this.multipleSelection.forEach(item => item.IsSystemRole && (isValid = false));
                if(!isValid){
                    this.Utils.messageBox(`系统角色不能被删除.`, "error");
                    return false;
                }

                var This = this;
                this.Utils.confirm({
                    title:"提示",
                    content:"删除后数据不可恢复,是否确认?"
                },()=>{
                    var ids = this.Utils.selectionsToArr(this.multipleSelection, "SysRoleId");
                    this.$ajax.send("omsapi/sysrole/delete", "post", ids, (data) => {
                       This.onPageChange(This.queryParam);
                       This.Utils.messageBox("删除成功.","succes");
                    });
                },()=>{
                    This.Utils.messageBox("已取消.","info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        }
    }
</script>
<style>

</style>